<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="w-10 h-10 iconify iconify--clarity"
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
  >
    <path
      fill="currentColor"
      d="m19.41 18l7.29-7.29a1 1 0 0 0-1.41-1.41L18 16.59l-7.29-7.3A1 1 0 0 0 9.3 10.7l7.29 7.3l-7.3 7.29a1 1 0 1 0 1.41 1.41l7.3-7.29l7.29 7.29a1 1 0 0 0 1.41-1.41Z"
      class="clr-i-outline clr-i-outline-path-1"
    ></path>
    <path fill="none" d="M0 0h36v36H0z"></path>
  </svg>
</template>
